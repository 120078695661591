import { useAppsStore } from "~/store/apps";

export async function useGetActiveNav(menu, isSettings) {
  const appsStore = useAppsStore();

  if (!appsStore.apps?.length && useGetToken() && !useConfig().isClient) {
    await appsStore.getAppsList();
  }

  if (!menu?.length) {
    appsStore.activeSubnav = null;
    appsStore.activeNav = null;
    appsStore.activeLink = "";
  }

  const findActiveNav = (item) =>
    item?.link === appsStore.activeLink?.toString() ||
    (item?.functions?.flat(Infinity)?.some(findActiveNav) ?? false);

  appsStore.activeNav = menu?.find(findActiveNav) ?? null;

  if (appsStore.activeNav?.functions?.flat(Infinity)?.length && !isSettings) {
    const findActiveSubnav = (subitem) =>
      subitem.link === appsStore.activeLink?.toString() ||
      (subitem.functions?.flat(Infinity)?.some(findActiveSubnav) ?? false);

    appsStore.activeSubnav =
      appsStore.activeNav?.functions?.flat(Infinity)?.find(findActiveSubnav) ??
      null;
  }

  if (isSettings) {
    appsStore.activeSubnav = null;
  }
}
